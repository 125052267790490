<template>
  <div style="overflow-y: auto;position: relative;padding-bottom: 20px">
    <el-button size="medium" style="width: 72px;padding: 10px 7px;position: absolute;top: 45px;left: 35px" @click="goBack" type="primary"
      >返回</el-button
    >
    <el-button
      size="medium"
      style="padding: 10px 7px;position: absolute;top: 45px;right: 35px"
      :loading="downPDF"
      @click="downPdf()"
      type="primary"
      >{{ title }}</el-button
    >
    <div id="app-report" class="app-report">
      <h2 class="re-title">{{ $route.query['examName'] }}</h2>
      <h3 class="re-dec">个人分析报告</h3>

      <div class="pre-basis">
        <h3>一、基本信息</h3>
        <div class="ba-content" style="height: 230px;">
          <div class="ba-left">
            <p style="margin-bottom: 3px">
              <span class="firstSpan">姓名</span>
              <span class="firstSpan">{{ basiInfo.userName }}</span>
            </p>
            <p style="margin-bottom: 3px">
              <span class="secSpan">工号</span>
              <span class="secSpan">{{ basiInfo.jobNumber }}</span>
            </p>
            <p class="listP">
              <span class="secSpan">分数</span>
              <span class="secSpan">{{ basiInfo.sore }}</span>
            </p>
            <p class="listP">
              <span class="secSpan">排名</span>
              <span class="secSpan">{{ basiInfo.ranking }}</span>
            </p>
            <p class="listP">
              <span class="secSpan">平均分</span>
              <span class="secSpan">{{ basiInfo.avgScore }}</span>
            </p>
            <p class="listP">
              <span class="secSpan">测试用时</span>
              <span class="secSpan">{{ basiInfo.duration }}</span>
            </p>
            <p class="listP">
              <span class="secSpan">测试时间</span>
              <span class="secSpan">{{ basiInfo.submitTime }}</span>
            </p>
            <p class="listP">
              <span class="secSpan">测试主题</span>
              <span class="secSpan textEllipsis" :title="basiInfo.examName">{{ basiInfo.examName }}</span>
            </p>
            <!--            <p class="listP" v-for="(n, index) in baInfoL" :key="`n-${index}`">-->
            <!--              <span class="secSpan">{{n.pointN}}</span>-->
            <!--              <span class="secSpan">{{n.getValue}}</span>-->
            <!--            </p>-->
          </div>
          <div class="ba-right" id="pointsEchart" style="width: 100%;height: 100%"></div>
        </div>
      </div>

      <div class="examAnalysis">
        <h3>二、测试结果分析</h3>
        <div class="examRar">
          <div class="title">1.技能结果</div>
          <div class="radar" id="radar"></div>
          <div class="desc">
            按编程基础、编程操作、专业基础、工程能力 4 个类目统计掌握程度。此 4 个类目掌握率统计表如下：
          </div>
          <div class="tableDate">
            <el-table width="100%" :data="abLData">
              <el-table-column align="center" show-overflow-tooltip label="类目名称" prop="categoryName" />
              <el-table-column align="center" width="140" label="掌握率" prop="userRate">
                <template slot-scope="scope">
                  <div>{{ (scope.row.userRate * 100).toFixed(2) + '%' }}</div>
                </template>
              </el-table-column>
              <el-table-column align="center" width="140" label="平均掌握率" prop="averageRate">
                <template slot-scope="scope">
                  <div>
                    {{ (scope.row.averageRate * 100).toFixed(2) + '%' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" width="140" label="差异" prop="differenceRate">
                <template slot-scope="scope">
                  <div :style="Number(scope.row.differenceRate) < 0 ? 'color: red' : 'color: green'">
                    {{ (scope.row.differenceRate * 100).toFixed(2) + '%' }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!--            <div style="justify-content: center;">-->
            <!--              <p class="title" v-for="(a, aIndex) in abList" :key="`a-${aIndex}`">-->
            <!--                {{a.name}}-->
            <!--              </p>-->
            <!--            </div>-->
            <!--            <div style="flex-direction: column;">-->
            <!--              <div class="data" v-for="(d, dIndex) in abLData" :key="`d-${dIndex}`">-->
            <!--                <p class="dataSpan">{{d.categoryName}}</p>-->
            <!--                <p class="dataSpan">{{(d.userRate*100).toFixed(2) + '%'}}</p>-->
            <!--                <p class="dataSpan">{{(d.averageRate*100).toFixed(2) + '%'}}</p>-->
            <!--                <p class="dataSpan" :style="Number(d.differenceRate) < 0 ? 'color: red' : 'color: green'">{{(d.differenceRate * 100).toFixed(2)}}</p>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>

      <div class="aniliDetail">
        <h3>三、详细技能分析</h3>
        <div class="adTitle">1.详细技能得分分布如下图</div>
        <div class="adInfo">
          <div style="justify-content: center;">
            <span style="background: #dedede;padding:3px 9px;" />&nbsp;总分&nbsp;&nbsp;
            <span style="background: #A1CC80;padding:7px 9px;" />&nbsp;结果
          </div>
          <div v-for="(s, index) in scoreList" :key="`s-${index}`">
            <span
              class="fontStyle"
              style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-align: right;width: 35%;"
              :title="s.knowledgePointName + '[' + s.categoryName + ']'"
              >{{ s.knowledgePointName + '[' + s.categoryName + ']' }}&nbsp;&nbsp;</span
            >
            <div style="position: relative;">
              <el-slider disabled class="sliderEle" v-model="s.schedF" />
              <span style="position: absolute;left: 50%;top: 8px;color: #888;opacity: 0.7;">{{ s.fivePointScore }}</span>
            </div>
            <span>&nbsp;&nbsp;5</span>
          </div>
        </div>
        <div class="adInfo" style="margin-top: 20px;">
          <div style="justify-content: center;">
            <span style="background: #A1CC80;padding:7px 9px;" />&nbsp;得分&nbsp;&nbsp;
            <span style="background: #dedede;padding:3px 9px;" />&nbsp;总分数
          </div>
          <div v-for="(s, index) in scoreList" :key="`s-${index}`">
            <span
              class="fontStyle"
              style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-align: right;width: 35%;"
              :title="s.knowledgePointName + '[' + s.categoryName + ']'"
              >{{ s.knowledgePointName + '[' + s.categoryName + ']' }}&nbsp;&nbsp;</span
            >
            <div style="position: relative;">
              <el-slider disabled class="sliderEle" v-model="s.schedU" />
              <span style="position: absolute;left: 50%;top: 8px;color: #888;opacity: 0.7;">{{ s.userScore }}</span>
            </div>
            <span>&nbsp;&nbsp;{{ s.totalScore }}</span>
          </div>
        </div>
      </div>

      <div class="questionDetail">
        <h3>四、题目考试详细结果</h3>
        <div class="qd-table">
          <div class="tTitle">
            <div v-for="(qd, index) in questionDeList" :key="`qd-${index}`" :style="{ width: `${qd.wid}px` }">
              {{ qd.name }}
            </div>
          </div>
          <div class="data">
            <!--            data-->
            <div class="qtStyle" v-for="(qtl, index) in questionTypeL" :key="`qtl-${index}`">
              <div>{{ index + 1 }}</div>
              <div :title="qtl.knowledgePoint">
                <span style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ qtl.knowledgePoint }}</span>
              </div>
              <div>{{ qtl.sectionName }}</div>
              <div>{{ qtl.correctRate }}</div>
              <div :style="qtl.result === '正确' ? 'color: green' : 'color: red'">
                {{ qtl.result }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultPoinOp from './pointOption'
import { defaultOp } from './radarOption'
import { questionTypeNew } from '@util/questionType'
import { pdfD } from '@util/downPdf'
import { pdfD2 } from '@util/pdfDown2'
import examReport from '../../../../../api/examReport'
const defaultBaInfo = [
  {
    pointN: '分数',
    getValue: 84
  },
  {
    pointN: '排名',
    getValue: '第5名，总26人'
  },
  {
    pointN: '平均分',
    getValue: '66.5'
  },
  {
    pointN: '测试用时',
    getValue: '89分钟'
  },
  {
    pointN: '测试时间',
    getValue: '2020.9.18'
  },
  {
    pointN: '测试主体',
    getValue: '新员工测试'
  }
]
const defaultAblist = [{ name: '类目名称' }, { name: '掌握率' }, { name: '平均掌握率' }, { name: '差异' }]
const defaultQuDe = [
  { name: '序号', wid: 150 },
  { name: '技能' },
  { name: '题型', wid: 200 },
  { name: '正确率', wid: 200 },
  { name: '结果', wid: 200 }
]
export default {
  name: 'myReport',
  data() {
    return {
      baInfoL: Object.assign([], defaultBaInfo),
      pointsOp: { ...defaultPoinOp },
      radarOp: { ...defaultOp },
      abList: Object.assign([], defaultAblist),
      abLData: [],
      scoreList: [],
      value: 5,
      title: '加载中...',
      downPDF: false, // 是否允许下载
      questionDeList: Object.assign([], defaultQuDe),
      questionTypeL: [],
      basiInfo: {
        examName: null,
        duration: null,
        submitTime: null,
        avgScore: null,
        ranking: null,
        sore: null,
        userName: null
      },
      userId: null,
      anPaId: null,
      id: null
    }
  },
  filters: {
    getQuestionType(type) {
      return questionTypeNew(type)
    },
    getResult(type) {
      if (type === 0) return '正确'
      else return '错误'
    }
  },
  created() {
    this.downPDF = true
    let res = this.$handleParams(`myReportDate`)
    this.userId = res.userId
    this.anPaId = res.anPaId
    this.id = res.id
    this.getCateRate()
    this.getPersonInfo()
    this.getPerSectionInfo()
    this.getKnowLedgeScore()
    this.getQuestionRate()
  },
  mounted() {
    setTimeout(() => {
      this.downPDF = false
      this.title = 'PDF导出'
    }, 3500)
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    message(msg, type) {
      this.$message({ message: msg, type: type })
    },
    downPdf() {
      if (!this.basiInfo.userName) return this.message('导出失败,个人信息不全', 'warning')
      this.downPDF = true
      this.title = '导出中...'
      pdfD2(document.getElementById('app-report'), `${this.basiInfo.userName}.pdf`)
      setTimeout(() => {
        this.downPDF = false
        this.title = 'PDF导出'
      }, 2000)
    },
    getPerSectionInfo() {
      examReport.getPerSectionInfo(this.anPaId).then(res => {
        this.initPointsE(res.res)
      })
    },
    getPersonInfo() {
      examReport.getPersonInfo(this.anPaId).then(res => {
        this.basiInfo = res.res
      })
    },
    getQuestionRate() {
      examReport.getPerExamRate(this.anPaId).then(res => {
        this.questionTypeL = res.res
        this.downPDF = false
        this.title = 'PDF导出'
      })
    },
    getKnowLedgeScore() {
      examReport.getKnowLedgeScore(this.id, this.userId).then(res => {
        // console.log(res.res)
        this.scoreList = res.res
        this.scoreList.forEach(s => {
          s.schedF = (s.fivePointScore / 5) * 100
          s.schedU = (s.userScore / s.totalScore) * 100
        })
      })
    },
    getCateRate() {
      examReport.getCateRate(this.id, this.userId).then(res => {
        this.abLData = res.res
        this.initRadarE(this.abLData)
      })
    },
    initPointsE(data) {
      let myChart = this.$echarts.init(document.getElementById('pointsEchart'))
      this.pointsOp.dataset.source = []
      data.forEach(d => {
        this.pointsOp.dataset.source.push({
          product: d.sectionName,
          得分: d.score,
          总分: d.totalScore
        })
      })
      myChart.setOption(this.pointsOp)
    },
    initRadarE(data) {
      let myChart = this.$echarts.init(document.getElementById('radar'))
      let a = this.radarOp.series[0].data
      a.push(
        {
          name: '掌握率',
          value: [],
          itemStyle: {
            normal: {
              color: '#e75c48',
              lineStyle: {
                width: 1,
                color: '#e75c48'
              }
            }
          }
        },
        {
          name: '平均掌握率',
          value: [],
          itemStyle: {
            normal: {
              color: '#63a8a5',
              lineStyle: {
                width: 1,
                color: '#63a8a5'
              }
            }
          }
        }
      )
      let list = []
      let list2 = []
      this.radarOp.radar.indicator = []
      data.forEach(d => {
        list.push(d.userRate)
        list2.push(d.averageRate)
        this.radarOp.radar.indicator.push({
          name: d.categoryName,
          max: 1
          // axisLabel: {
          //   show: true,
          //   fontSize: 12,
          //   color: '#838D9E',
          //   showMaxLabel: false, //不显示最大值，即外圈不显示数字30
          //   showMinLabel: false //显示最小数字，即中心点显示0
          // }
        })
      })
      a[0].value = list
      a[1].value = list2
      myChart.setOption(this.radarOp)
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../../../theme/index';
.fontStyle {
  letter-spacing: 0;
  font-size: 14px;
}
</style>
